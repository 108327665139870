import Headline from "components/general/headline"
import Layout from "components/layout"
import VideosComponent from "components/videos/videos"
import * as React from "react"

const VideosPage = () => {
  const seo = {
    metaTitle: "Videos",
    metaDescription:
      "Videos vergangener Faustball Spieltage der Faustballer des MTV Vorsfelde",
  }

  return (
    <Layout seo={seo}>
      <div className="main-box">
        <Headline
          headline="Videos"
          subHeadline="Aufnahmen von Spieltagen und Turnieren"
        />

        <div className="mt-12">
          <VideosComponent />
        </div>
      </div>
    </Layout>
  )
}

export default VideosPage
