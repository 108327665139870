import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import VideoComponent from "./video"

const VideosComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiVideo(sort: {fields: createdAt, order: DESC}) {
        edges {
          node {
            id
            title
            url
          }
        }
      }
    }
  `)

  return (
    <div className="space-y-8">
      {data.allStrapiVideo.edges
        .map(v => v.node)
        .map(video => {
          return (
            <VideoComponent
              key={video.id}
              title={video.title}
              url={video.url}
            />
          )
        })}
    </div>
  )
}

export default VideosComponent
