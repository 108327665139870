import * as React from "react"
import { FC } from "react"

export interface VideoProps {
  title: string
  url: URL
}

const VideoComponent: FC<VideoProps> = ({ title = "", url = undefined }) => {
  if (title === "" || url === undefined) {
    return <></>
  }

  return (
    <div>
      <h3 className="h3 mb-4">{title}</h3>
      <div className="overflow-auto rounded-xl border border-gray-900 bg-gray-700 bg-opacity-30 p-4">
        <iframe
          className="aspect-video w-full"
          src={url.toString()}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default VideoComponent
